<template data-app>
  <div class="dashboard-content">
    <!-- Content -->
    <div class="card">
      <div class="filters my-2" style="padding: 10px 10px 0px 10px">
        <h3 class="text-center">
          {{ $t('Views.AdminCountries.title')
          }}<v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="#0c2c7c"
                dark
                class="mb-2"
                style="float: right"
                @click.prevent="handleCreate()"
                >{{ $t('Views.AdminCountries.createButton') }}
              </v-btn></template
            ><span>{{ $t('Views.AdminCountries.createText') }}</span></v-tooltip
          >
        </h3>
        <label>{{ $t('Views.AdminCountries.filterText') }}:</label>

        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="my-2"
          ><v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <v-text-field
                dense
                solo
                hide-details="auto"
                :label="$t('Views.AdminCountries.filterSearch')"
                v-model="filters.search"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="1" md="2" sm="6" style="padding: 1px 2px 1px 2px">
              <multiselect
                :key="$t('Views.AdminCountries.filterActive')"
                style="min-width: 80px"
                track-by="id"
                label="name"
                deselect-label=""
                select-label=""
                :placeholder="$t('Views.AdminCountries.filterActive')"
                v-model="filters.active"
                :options="activeOptions"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              ></multiselect>
            </v-col>

            <v-btn
              color="#0c2c7c"
              style="min-width: auto; position: absolute; right: 50px; height: 25px"
              dark
              class="px-1 py-1"
              v-if="filterActivated"
              @click.prevent="
                filterActivated = false
                cleanFilters()
                getCountries()
              "
            >
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="#0c2c7c"
                  style="min-width: auto; position: absolute; right: 10px; height: 25px"
                  dark
                  class="px-1 py-1"
                  @click.prevent="
                    filterActivated = true
                    getCountries()
                  "
                  ><v-icon dark> mdi-filter </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Views.AdminCountries.filterButtonText') }}</span>
            </v-tooltip>
          </v-row>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="false"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :hideFooter="true"
        :multiSort="false"
        :loading="loading"
        :class="'mb-4'"
        :sortable="true"
        :page="page"
        :pages="pages"
        :extraButtoms="false"
        :showCreate="true"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </div>
    <modal name="crudModal" :height="'75%'" :width="'80%'" :adaptive="true">
      <h3 class="text-center" v-if="flow == 'create'">
        {{ $t('Views.AdminCountries.createText') }}
      </h3>
      <h3 class="text-center" v-if="flow == 'edit'">
        {{ $t('Views.AdminCountries.editText') }}
      </h3>
      <div class="card" style="padding: 2rem; margin: 20px">
        <v-form ref="formCompany" v-model="valid" style="width: 100%">
          <v-row>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :rules="[rules.required, rules.max]"
                :label="$t('Views.AdminCountries.labelName')"
                v-model="name"
                counter
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :rules="[rules.required, rules.max2]"
                :label="$t('Views.AdminCountries.labelIso2')"
                v-model="iso2"
                :counter="2"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                v-mask="phoneMask"
                :rules="[rules.required]"
                :label="$t('Views.AdminCountries.labelDial')"
                v-model="dial"
                counter
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :rules="[rules.required, rules.max3]"
                :label="$t('Views.AdminCountries.labelCurrency')"
                v-model="currency"
                :counter="3"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-row no-gutters>
                <a
                  style="width: 40%"
                  :href="item.flag"
                  v-if="flow == 'edit' && item.flag"
                  target="_blank"
                  ><v-img :src="item.flag" alt="" width="50" height="40"></v-img
                ></a>
                <v-text-field
                  v-if="flow == 'edit' && item.flag"
                  style="width: 60%"
                  :label="$t('Views.AdminCountries.labelFlag')"
                  v-model="flag"
                  :rules="[rules.required, rules.url]"
                  persistent-hint
                  :hint="$t('Views.AdminCountries.labelFlagExample')"
                ></v-text-field
                ><v-text-field
                  v-else
                  :label="$t('Views.AdminCountries.labelFlag')"
                  v-model="flag"
                  :rules="[rules.required, rules.url]"
                  persistent-hint
                  :hint="$t('Views.AdminCountries.labelFlagExample')"
                ></v-text-field
              ></v-row>
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12">
              <v-checkbox
                :label="$t('Views.AdminCountries.labelActive')"
                v-model="active"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </div>

      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            :dark="valid"
            style="margin: auto; margin-top: 10px"
            v-if="flow == 'create'"
            :disabled="!valid"
            color="#0c2c7c"
            @click="createItem()"
          >
            {{ $t('Views.AdminCountries.createButton') }}
          </v-btn>
          <v-btn
            :dark="valid"
            style="margin: auto; margin-top: 10px"
            v-if="flow == 'edit'"
            :disabled="!valid"
            color="#0c2c7c"
            @click="saveItem()"
          >
            {{ $t('Views.AdminCountries.saveButton') }}
          </v-btn>

          <v-btn
            dark
            style="margin: auto; margin-top: 10px"
            color="#0c2c7c"
            @click="reset"
          >
            {{ $t('Views.AdminCountries.cleanButton') }}
          </v-btn>
          <v-btn
            dark
            style="margin: auto; margin-top: 10px"
            color="#0c2c7c"
            @click="$modal.hide('crudModal')"
          >
            {{ $t('Views.AdminCountries.cancelButton') }}
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import SimpleList from 'Components/Listing/SimpleList.vue'

  export default {
    name: 'Countries',
    components: {
      SimpleList,
    },
    data() {
      return {
        loading: false,
        items: [],
        countries: [],
        categories: [],
        page: 1,
        pages: 1,
        item: {},
        phoneMask: [
          '+',
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
        ],
        filterActivated: false,
        filters: {
          search: '',
          active: { id: 1, name: 'Activos' },
        },
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        name: '',
        iso2: '',
        active: false,
        currency: '',
        dial: '',
        flag: '',
        loadingText: 'Cargando registros',
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => v.length >= 8 || '8 caracteres como mínimo',
          max: (v) => v.length <= 140 || '140 caracteres como máximo',
          max2: (v) => (v ? v.length === 2 : true) || 'Debe poser 2 caracteres',
          max3: (v) => (v ? v.length === 3 : true) || 'Debe poser 3 caracteres',
          url: (v) => this.isURL(v) || 'La URL es inválida',
        },
      }
    },
    created() {
      this.getCountries()
    },
    computed: {
      headers() {
        return [
          { text: this.$t('Views.AdminCountries.headerName'), value: 'name' },
          { text: this.$t('Views.AdminCountries.headerIso2'), value: 'iso2' },
          { text: this.$t('Views.AdminCountries.headerCurrency'), value: 'currency' },
          { text: this.$t('Views.AdminCountries.headerDial'), value: 'dial' },
          { text: this.$t('Views.AdminCountries.headerActive'), value: 'activeText' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '100px' },
        ]
      },
      orderOptions() {
        return [
          {
            id: 1,
            name: this.$t('Views.AdminCountries.orderOption1'),
            order: 'created_at',
            by: 'desc',
          },
          {
            id: 2,
            name: this.$t('Views.AdminCountries.orderOption2'),
            order: 'created_at',
            by: 'asc',
          },
          {
            id: 3,
            name: this.$t('Views.AdminCountries.orderOption3'),
            order: 'name',
            by: 'asc',
          },
          {
            id: 4,
            name: this.$t('Views.AdminCountries.orderOption4'),
            order: 'updated_at',
            by: 'desc',
          },
        ]
      },
      activeOptions() {
        return [
          { id: 1, name: this.$t('Views.AdminCountries.filterActiveOption1') },
          { id: 0, name: this.$t('Views.AdminCountries.filterActiveOption2') },
        ]
      },
      orderBy() {
        return {
          id: 3,
          name: this.$t('Views.AdminCountries.orderOption3'),
          order: 'name',
          by: 'asc',
        }
      },
    },
    methods: {
      isURL(str) {
        if (!str || str === '') return true
        let url
        try {
          url = new URL(str)
        } catch (_) {
          return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
      },
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getCountries()
      },
      fullName({ name, lastname, email }) {
        if (name && name != 'Todos') {
          if (lastname && email) {
            return `${name} ${lastname} | ${email}`
          } else {
            return name
          }
        } else {
          return name
        }
      },
      cleanFilters() {
        this.filters = {
          search: '',
          active: { id: 1, name: 'Activos' },
        }
      },

      async getCountries() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        let activeString = ''
        let searchString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        activeString = '&active=' + this.filters.active.id
        if (this.filterActivated) {
          if (this.filters.search != '') {
            searchString = '&search=' + this.filters.search
          }
        }
        queryParams = searchString + activeString
        axios
          .get('countries?page=' + this.page + orderString + queryParams)
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.map((x) => {
              x.activeText = x.active ? 'Si' : 'No'
            })
            this.loading = false
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.name = ''
        this.iso2 = ''
        this.dial = ''
        this.currency = ''
        this.flag = ''
        this.active = false
        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.name = this.item.name
        this.iso2 = this.item.iso2
        this.dial = this.item.dial
        this.currency = this.item.currency
        this.flag = this.item.flag
        this.active = this.item.active
        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.form.validate()
      },

      reset() {
        this.$refs.form.reset()
      },

      async createItem() {
        axios
          .post('countries', {
            name: this.name,
            iso2: this.iso2,
            dial: this.dial,
            currency: this.currency,
            flag: this.flag,
            active: this.active ? 1 : 0,
          })
          .then((response) => {
            this.page = 1
            this.getCountries()
            this.$modal.hide('crudModal')
          })
      },

      async saveItem() {
        axios
          .patch('countries/' + this.item.id, {
            name: this.name,
            iso2: this.iso2,
            dial: this.dial,
            currency: this.currency,
            flag: this.flag,
            active: this.active ? 1 : 0,
          })
          .then(() => {
            this.page = 1
            this.getCountries()
            this.$modal.hide('crudModal')
          })
      },

      async deleteItem(item) {
        axios.delete('countries/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getCountries()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getCountries()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/assets/scss/cruds/_crudStyles.scss';
  .ql-editor .ql-video {
    display: block;
    max-width: 100%;
    width: 70%;
    margin: auto;
    height: 300px;
  }
  .v-textarea textarea {
    height: 80px;
  }
  .text-box strong {
    font-weight: 600;
  }
</style>
